import Navbar from "./component/pages/Navbar";
import "./component/scss/Main.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./component/pages/Home";
import "./App.scss";
import Form1 from "./component/pages/Form1";
import Page404 from "./component/pages/Page404";
import Swipers from "./component/pages/Swipers";
import Course from "./component/pages/Course";
import Docs from "./component/pages/Docs";
import Grants from "./component/pages/Grants";
import Events from "./component/pages/Events";
import Careers from "./component/pages/Careers";
import Footer from "./component/pages/Footer";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="min-h-90vh">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/course" element={<Course />} />
          <Route path="/docs" element={<Docs />} />
          <Route path="/grants" element={<Grants />} />
          <Route path="/events" element={<Events />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/form" element={<Form1 />} />
          <Route path="/swipers" element={<Swipers />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
