import React from "react";

function Careers() {
  return (
    <div className="">
      <div className="t-a-c f-s-3 f-w-600 m-b-3 m-t-5 ">Careers</div>
      <div className="max-w-40 b-r-10 d-b m-a f-s-1_25 box_clr pa-2">
        Join the Maal Data Labs team and be part of a dynamic community
        committed to revolutionizing the blockchain landscape. We're on the
        lookout for passionate individuals who thrive on innovation and are
        ready to shape the future of decentralized technologies. Explore
        exciting career opportunities with us and contribute to the development
        of cutting-edge products and solutions that redefine the possibilities
        of blockchain. If you're driven by curiosity, creativity, and a desire
        to make a significant impact, Maal Data Labs welcomes you to explore a
        rewarding career journey with us. Unleash your potential and grow with
        us in the world of blockchain innovation.
      </div>
    </div>
  );
}

export default Careers;
