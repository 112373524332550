import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

import "./styles.css";

// import required modules
import { EffectCards } from "swiper/modules";

export default function App() {
  return (
    <>
      <Swiper
        effect={"cards"}
        grabCursor={true}
        modules={[EffectCards]}
        className="mySwiper"
      >
        <SwiperSlide>
          {" "}
          <div className="box_clr h-32 pa-2 m-q-b-m-y-3 w-100  t-a-c  h-100 ">
            <div className="d-f f-d-c h-100">
              <div className="m-b-1 f-s-1_5">Dato Dr. Shamshudeen Yunus</div>
              <div className="m-b-3 gray_clr">Chief Strategist Officer</div>
              <div className="m-b-3 gray_clr max-w-30 d-b m-a">
                Dr. Sham holds a PhD in Finance. Dr. Sham has in-depth knowledge
                and 40 years of experience in the field of Banking, Corporate
                Finance & international trade.
              </div>
              <div className="m-b-3 gray_clr max-w-30 d-b m-a">
                He kicked off his impressive career as the youngest EDP. Manager
                of the Kuala Lumpur Stock Exchange at the age of 22. By 1986, he
                received South East Asia’s young Entrepreneur Award.
              </div>
              <div className=" m-t-a">
                <a
                  href="https://www.linkedin.com/in/Dato-Dr-Sham/"
                  target="_blank"
                >
                  <button className="linear_gradient b-r-10 b-n p-x-3 p-y-1">
                    Read More
                  </button>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          {" "}
          <div className="box_clr h-32  m-q-b-m-y-3 w-100   pa-2 t-a-c  ">
            <div className="d-f f-d-c h-100">
              <div className="m-b-1 f-s-1_5">Mr. John Pellegrini</div>
              <div className="m-b-5 gray_clr">Chief Executive Officer</div>
              <div className="m-b-5 gray_clr max-w-30 m-q-b-m-a-5 ">
                John Pellegrini BSc (Physics), Grad. Dip. (Physics) and an MBA
                (Finance). He has worked at some of the leading international
                banks, investment banks, commodities exchanges in New York,
                Chicago, London, Sydney, and Singapore.
              </div>
              <div className="m-t-a">
                <a
                  href="https://www.linkedin.com/in/johnpellegrini/"
                  target="_blank"
                >
                  <button className="linear_gradient b-r-10 b-n p-x-3 p-y-1">
                    Read More
                  </button>
                </a>
              </div>
            </div>{" "}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <div className="box_clr h-32 pa-2 t-a-c w-100 m-y-3  ">
            <div className="d-f f-d-c h-100">
              <div className="m-b-1 f-s-1_5">Mr. Dimitri Rizzi</div>
              <div className="m-b-5 gray_clr">Chief Operations Officer</div>
              <div className="m-b-5 gray_clr max-w-30 d-b m-a">
                Dimitri Rizzi is a highly accomplished professional with a
                distinguished educational background in aw and holds an MBA.
                With over a decade of business experience in finance, he has
                steered a diverse portfolio of{" "}
              </div>
              <div className="m-t-a">
                <a
                  href="https://www.linkedin.com/in/dimitri-rizzi/"
                  target="_blank"
                >
                  <button className="linear_gradient b-r-10 b-n p-x-3 p-y-1">
                    Read More
                  </button>
                </a>
              </div>
            </div>{" "}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="box_clr h-32 pa-2 w-100 t-a-c m-y-3  ">
            <div className="d-f f-d-c h-100">
              <div className="m-b-1 f-s-1_5">Mr. Arun Kumar</div>
              <div className="m-b-4 gray_clr">Lead Developer / Dy CTO</div>
              <div className="m-b-4 gray_clr max-w-30 d-b m-a">
                Having spent the last 4 years specializing in Solidity, he has
                dedicated his expertise to the development of various
                decentralized applications (DApps). With a deep technical
                understanding and a results-driven approach his focus remains on
                ensuring our technological advancements align with our strategic
                objectives. His commitment is to deliver robust and efficient
                solutions, ensuring we stay at the forefront of innovation in
                the blockchain space.
              </div>
              <div className="m-t-a">
                <a href="https://www.linkedin.com/in/arrnaya/" target="_blank">
                  <button className="linear_gradient b-r-10 b-n p-x-3 p-y-1">
                    Read More
                  </button>
                </a>
              </div>
            </div>{" "}
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
