import React from "react";
import { Fade, Bounce } from "react-reveal";
import logo from "../Image/nfe-logo.png";
import { FaTelegramPlane } from "react-icons/fa";
import { AiFillFacebook } from "react-icons/ai";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div className="section">
        <div className="container-kws">
          <div className="d-f  j-c-s-b flex-wrap">
            <div className="m-y-3">
              <Bounce top>
                {" "}
                <img src={logo} className="m-b-1" />{" "}
              </Bounce>{" "}
              <div className="max-w-15">
                <div className="f-s-1_25 f-w-800 m-b-2 l-h-1_1 ">
                  Empowering the Decentralised Web
                </div>
                <div className="gray_clr f-s-1_25 m-b-2">
                  Maal Data Lab identifies, invests, and
                  empowers viable blockchain entrepreneurs,
                  startups, and communities.
                </div>
              </div>
              <div className="gray_clr">
                © 2024 Maal Data Labs. All Rights Reserved
              </div>
            </div>

            <div className="m-y-3">
              <div className="d-f  flex-wrap">
                <div className="m-r-3">
                  <div className="f-s-1_25 m-b-1">
                    Quick Links
                  </div>
                  <a href="/course">
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      Certified Blockchain Developer’s
                      Course
                    </div>
                  </a>
                  <a href="#about">
                    {" "}
                    <div className="m-b-1 gray_clr">
                      About Maal Data Labs
                    </div>
                  </a>
                  <a href="/careers">
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      Careers
                    </div>
                  </a>
                  <a href="/grants">
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      Grants
                    </div>
                  </a>
                  <a href="/events">
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      Events{" "}
                    </div>
                  </a>
                  <a href="/docs">
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      Docs{" "}
                    </div>
                  </a>
                  <a
                    href="https://docs.maalscan.io/"
                    target="_blank"
                  >
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      MaalChain Whitepaper{" "}
                    </div>
                  </a>
                  <a
                    href="https://www.lms.maaldatalabs.com/"
                    target="_blank"
                  >
                    {" "}
                    <div className=" m-b-1 gray_clr">
                      LMS{" "}
                    </div>
                  </a>
                </div>
                <div className="">
                  <div className="f-s-1_25 m-b-1">
                    Contact Us
                  </div>
                  <div className=" gray_clr">
                    developer@maaldatalabs.com
                  </div>
                </div>
              </div>
            </div>
            <div className="m-y-3">
              <div className="f-s-1_25 m-b-1">
                Social Media
              </div>
              <div className="d-f a-i-c gray_clr">
                <a
                  href="https://www.facebook.com/MaalChainOfficial"
                  target="_blank"
                >
                  <BsFacebook className="f-s-1_5 c-p m-r-1" />
                </a>
                <a
                  href="https://x.com/maalchain?s=21"
                  target="_blank"
                >
                  {" "}
                  <BsTwitter className="f-s-1_5 c-p m-r-1" />
                </a>
                <a
                  href="https://t.me/MaalChain"
                  target="_blank"
                >
                  {" "}
                  <FaTelegramPlane className="f-s-1_5 c-p" />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
