import React from "react";

function Careers() {
  return (
    <div className="">
      <div className="t-a-c f-s-3 f-w-600 m-b-3 m-t-5 ">Grants</div>
      <div className="max-w-40 b-r-10 d-b m-a f-s-1_25 box_clr pa-2">
        Want to innovate on MaalChain? Apply for a grant. (Should insert a form
        for builders)
      </div>
    
    </div>
  );
}

export default Careers;
