import React, { useState } from "react";
import { Fade, Bounce } from "react-reveal";
import rectangle from "../Image/Group 2354.png";
import triangle from "../Image/Group 2359.png";
import pencil from "../Image/pencil.png";
import certificate from "../Image/certificate.svg";
import "animate.css";
import left_triangle from "../Image/Group 2363.png";
import circle from "../Image/Group 2369.png";
import ramzmarketcap from "../Image/ramzmarketcap.png";
import cut_triangle from "../Image/Group 2439.png";
import logo from "../Image/nfe-logo.png";
import pansea from "../Image/pansea.png";
import Swipers from "./Swipers";
import ramzswap from "../Image/ramzswap.png";
import maal_certificate from "../Image/maal-certificate.png";
import maalscan_white from "../Image/maalscan_white.png";
import { AiFillFacebook } from "react-icons/ai";
import { BsFacebook, BsTwitter } from "react-icons/bs";
import { Parallax } from "react-scroll-parallax";
import Footer from "./Footer";

function Home() {
  const [showCertificate, setShowCertificate] = useState(false);

  const handleToggleCertificate = () => {
    setShowCertificate((prev) => !prev);
  };

  return (
    <>
      <div className=" ">
        <div className="container-kws">
          <div className="d-f a-i-c  j-c-s-b">
            <div className="max-w-30">
              <div className="f-s-3 f-w-800 m-b-2 l-h-1_1 ">
                Empowering the Decentralised Web
              </div>
              <div className="gray_clr f-s-1_25 m-b-2">
                Maal Data Lab identifies, invests, and empowers viable
                blockchain entrepreneurs, startups, and communities.
              </div>
              <div className="gray_clr f-s-1_25 m-b-3">
                We provide Technical and Vocational Education and Training
                (TVET) programs and back projects developed on MaalChain to
                foster the expansion of the broader MaalChain ecosystem and its
                utilities.{" "}
              </div>
              <div className="">
                <button className="linear_gradient p-x-3 p-y-1 b-r-10 b-n f-s-1_25 c-w animate__animated animate__backInRight">
                  APPLY TO LABS
                </button>
              </div>
            </div>

            <div className="pa-2 ps-r m-q-b-d-n">
              <div className="ps-a t_0 l_0 z-i-999">
                <Bounce left>
                  <img
                    src={triangle}
                    className=" animate__animated animate__backInUp max-w-9"
                  />
                </Bounce>
              </div>
              <Bounce right>
                <img
                  src={rectangle}
                  className="  animate__animated animate__backInDown max-h-90vh"
                />
              </Bounce>
            </div>
          </div>
        </div>

        <div className="section" id="about">
          <div className="container-kws">
            {/* Add scroll-triggered animations here */}
            <div className="m-q-d-f j-c-s-b">
              <div className="max-w-30">
                <div className="f-s-3 f-w-600 m-b-2">About Maal Data Labs</div>
                <div className="gray_clr m-b-2">
                  MAALChain aligns with the benefits of Maqasid al Shariah,
                  offering solutions and relief from difficulties. It is seen as
                  a necessity for simplifying people's lives and an essential
                  for wealth preservation. Technology contributes to enhancing
                  societal welfare and progress. The four features of Maqasid Al
                  Shariah that align perfectly with Blockchain are: transparency, security, anti-monopoly and facilitation.
                </div>{" "}
                <div className="gray_clr m-b-3">
                  MAALChain effectively addresses the challenges, concerns, and
                  scepticism associated with existing blockchains and dApps.
                  This is achieved by integrating an innovative Web3 technology
                  called “Concept-of-Identity,” which provides the ability to
                  achieve the level of compliance necessary for financial
                  institutions and governments to embrace blockchain technology
                  fully.
                </div>
                {/* <Fade right>
                  <div className="">
                    <button className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight m-b-3">
                      All Features
                    </button>
                  </div>
                </Fade> */}
              </div>
              <div className="m-q-d-f ">
                <div className="">
                  <Bounce bottom>
                    <div className="box_clr  pa-2 b-r-10 ma-1 m-q-max-w-30 m-b-3">
                      <div className="">
                        <div className="m-b-1">
                          <img
                            src={pencil}
                            className="animate__animated animate__backInDown m-y-2"
                          />
                        </div>
                        <div className="f-s-1_25 f-w-600 m-b-1">
                          Facilitation
                        </div>
                        <div className="gray_clr m-b-2">
                          MAALChain expedites transactions, saving time and
                          money, and enabling individuals to conduct more
                          transactions within a shorter timeframe.
                        </div>
                      </div>
                    </div>
                  </Bounce>
                  <Bounce bottom>
                    <div className="box_clr pa-2 b-r-10 ma-1 m-q-max-w-30 m-b-3">
                      <div className="">
                        <div className="m-b-1">
                          <img
                            src={pencil}
                            className="animate__animated animate__backInDown m-y-2"
                          />
                        </div>
                        <div className="f-s-1_25 f-w-600 m-b-1">
                          Transparency
                        </div>
                        <div className="gray_clr m-b-2">
                          MaalChain technology ensures enhanced transparency
                          while maintaining privacy intact.
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </div>
                <div className="m-q-m-t-7">
                  <Bounce top>
                    <div className="box_clr pa-2 b-r-10 ma-1 m-q-max-w-30 m-b-3">
                      <div className="">
                        <div className="m-b-1">
                          <img
                            src={pencil}
                            className="animate__animated animate__backInDown m-y-2"
                          />
                        </div>
                        <div className="f-s-1_25 f-w-600 m-b-1">Security</div>
                        <div className="gray_clr m-b-2">
                          MaalChain provides strong security through user
                          verification, preventing hackers from disrupting
                          transactions. The immutability of data ensures
                          information integrity.
                        </div>
                      </div>
                    </div>
                  </Bounce>
                  <Bounce top>
                    <div className="box_clr pa-2 b-r-10 ma-1 m-q-max-w-30 m-b-3">
                      <div className="">
                        <div className="m-b-1">
                          <img
                            src={pencil}
                            className="animate__animated animate__backInDown m-y-2"
                          />
                        </div>
                        <div className="f-s-1_25 f-w-600 m-b-1">
                          Anti-Monopoly
                        </div>
                        <div className="gray_clr m-b-2">
                          Blockchain technology can potentially eliminate the
                          requirement for intermediaries in various types of
                          transfers.
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </div>
              </div>
            </div>
            <div className="box_clr pa-2 b-r-10 ma-1 m-q-max-w-30">
              <p className="f-s-3 f-w-600 m-b-2">Digital Certificate</p>
              {!showCertificate ? (
                <div>
                  <div>
                    <Fade bottom>
                      <div
                        style={{
                          marginBottom: "1rem",
                        }}
                      >
                        <p>
                          Maal Data Labs is proud to be a recipient of the
                          prestigious Malaysia Digital Status, awarded on August
                          6, 2024. This recognition underscores our commitment
                          to driving digital innovation and contributing to
                          Malaysia's thriving digital economy.
                        </p>
                      </div>
                    </Fade>

                    <Fade bottom>
                      <div
                        style={{
                          marginBottom: "1rem",
                        }}
                      >
                        <p>
                          As a leading provider of innovative digital services, we are dedicated to leveraging data
                          and technology to create impactful solutions...
                        </p>
                      </div>
                    </Fade>
                  </div>
                  <button
                    style={{
                      marginTop: "2rem",
                    }}
                    className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight"
                    onClick={handleToggleCertificate}
                  >
                    Show More
                  </button>
                </div>
              ) : (
                <div>
                  <Fade bottom>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <p>
                        Maal Data Labs is proud to be a recipient of the
                        prestigious Malaysia Digital Status, awarded on August
                        6, 2024. This recognition underscores our commitment to
                        driving digital innovation and contributing to
                        Malaysia's thriving digital economy.
                      </p>
                    </div>
                  </Fade>

                  <Fade bottom>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <p>
                        As a leading provider of innovative digital services, we are dedicated to leveraging data and
                        technology to create impactful solutions. Our team of
                        experts is passionate about pushing the boundaries of
                        innovation and delivering exceptional value to our
                        clients.
                      </p>
                    </div>
                  </Fade>

                  <Fade bottom>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <p>
                        The Malaysia Digital Status is a testament to our hard
                        work and dedication. We are excited about the
                        opportunities this recognition presents and look forward
                        to continuing to make a significant impact in the
                        digital landscape.
                      </p>
                    </div>
                  </Fade>

                  <Fade bottom>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5rem",
                      }}
                    >
                      <img src={maal_certificate} alt="maal certificate" />
                    </div>
                  </Fade>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "5rem",
                    }}
                  >
                    <button
                      className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight"
                      onClick={handleToggleCertificate}
                    >
                      Show Less
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="section" id="portfolio">
          <div className="t-a-c f-s-3 f-w-600 m-b-5">
            Maal Ecosystem Projects
          </div>
          <div className="container-kws">
            <div className="d-f  j-c-c flex-wrap g-2 ">
              <div className="d-f f-d-c w-30 pa-3 b-r-10 box_clr ">
                <div className="t-a-c m-b-3">
                  {" "}
                  <img src={ramzmarketcap} className="w-21" />{" "}
                </div>
                <div className="m-b-3">
                  RamzMarketCap is a cryptocurrency market data platform that
                  provides information about the prices, market capitalization,
                  trading volumes, and other key metrics of various
                  cryptocurrencies. It serves as a comprehensive resource for
                  users to track the performance of individual cryptocurrencies
                  and the overall market, offering data on thousands of digital
                  assets across multiple exchanges. Tokens that meet the Shariah
                  criteria are awarded a prestigious gold ‘Check Mark’ as
                  verification
                </div>
                <div className="t-a-c m-t-a">
                  <a
                    href="https://ramzmarketcap.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight">
                      Visit Site
                    </button>
                  </a>{" "}
                </div>
              </div>
              <div className="d-f f-d-c w-30 pa-3 b-r-10 box_clr ">
                <div className="t-a-c m-b-3">
                  {" "}
                  <img src={pansea} className="w-9" />{" "}
                </div>
                <div className="m-b-3">
                  PANSea is a decentralized marketplace for buying and selling
                  non-fungible tokens (NFTs). It allows users to trade a variety
                  of digital assets, including art, collectibles, and virtual
                  real estate, by providing a platform for NFT discovery,
                  transactions, and ownership verification on the MAALChain
                  network.
                </div>
                <div className="t-a-c m-t-a">
                  <a
                    href="https://www.pansea.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight">
                      Visit Site
                    </button>
                  </a>{" "}
                </div>
              </div>{" "}
              <div className="d-f f-d-c w-30 pa-3 b-r-10 box_clr ">
                <div className="t-a-c m-b-3">
                  {" "}
                  <img src={ramzswap} className="w-7" />{" "}
                </div>
                <div className="m-b-3">
                  Ramzswap is a decentralized cryptocurrency exchange running on
                  the MAAL blockchain. It operates using automated smart
                  contracts to facilitate token swaps without the need for
                  traditional order books. Users can trade various MAAL-based
                  tokens directly from their wallets, and liquidity providers
                  earn fees for supplying assets to the decentralized liquidity
                  pools on the platform.
                </div>
                <div className="t-a-c m-t-a">
                  <a
                    href="https://app.ramzswap.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight">
                      Visit Site
                    </button>
                  </a>{" "}
                </div>
              </div>{" "}
              <div className="d-f f-d-c w-30 pa-3 b-r-10 box_clr ">
                <div className="t-a-c m-b-3">
                  {" "}
                  <img src={maalscan_white} className="w-6_5" />{" "}
                </div>
                <div className="m-b-3">
                  MAALscan is a blockchain explorer for the Maalchain network,
                  providing users with a platform to explore and analyze
                  transactions, addresses, and smart contracts on the MAAL
                  blockchain. It offers real-time data, transaction history, and
                  contract details, allowing users to monitor and verify
                  activities on the MAALChain network.
                </div>
                <div className="t-a-c m-t-a">
                  <a
                    href="https://maalscan.io/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="linear_gradient b-n p-x-3 p-y-1 b-r-10 c-w f-s-1_25 animate__animated animate__backInRight">
                      Visit Site
                    </button>
                  </a>{" "}
                </div>
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="m-q-b-d-n">
          <Parallax translateX={["-500px", "500px"]}>
            <div className="section" id="team">
              <div className="f-s-3 f-w-600 t-a-c m-b-2">Meet The Team</div>
              <div className="t-a-c  w-100 m-b-3">
                <div className="pa-3">
                  <Swipers />
                </div>
              </div>
            </div>
          </Parallax>
        </div>
        <div className="m-q-a-d-n">
          <div className="section" id="team">
            <div className="f-s-3 f-w-600 t-a-c m-b-2">Meet The Team</div>
            <div className="t-a-c  w-100 m-b-3">
              <div className="">
                <Swipers />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="section" id="careers">
          <div className="container-kws">
            <div className="f-s-3 m-b-3 f-w-600 t-a-c ">Careers</div>
            <div className="max-w-30 t-a-c m-a d-b">
              The Certified Blockchain Developer course aims to provide a deeper
              understanding of blockchains with greater insights into the key
              blockchain concepts. It is an exhaustive training and exam-based
              program which aims to provide proof of knowledge to the
              certificate holder within the blockchain space.
            </div>
          </div>
        </div> */}
        <div className="section " id="applynow">
          <div className="box_clr  p-y-3">
            <div className="container-kws d-f a-i-c j-c-s-b flex-wrap">
              <div className="m-y-2 ">
                {" "}
                <div className="m-b-1 f-s-2 f-w-600">
                  Apply To Maal Data Labs
                </div>
                <div className="gray_clr max-w-20">
                  Contact us at developer@maaldatalabs.com for inquiries and
                  supplementary materials.
                </div>
              </div>
              <div className="m-y-2 ">
                <button className="linear_gradient p-x-3 p-y-1 b-n b-r-10">
                  Apply Labs
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
