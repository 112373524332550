import React from "react";

function Careers() {
  return (
    <div className="">
      <div className="t-a-c f-s-3 f-w-600 m-b-3 m-t-5 ">
        Certified Blockchain Developer’s Course
      </div>
      <div className="max-w-40 b-r-10 d-b m-a f-s-1_25 box_clr pa-2">
        The Certified Blockchain Developer course aims to provide a deeper
        understanding of blockchains with greater insights into the key
        blockchain concepts. It is an exhaustive training and exam-based program
        which aims to provide proof of knowledge to the certificate holder
        within the blockchain space.
      </div>
    </div>
  );
}

export default Careers;
