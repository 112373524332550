import React from "react";
import coming from "../Image/comingsoon.jpg";

function Careers() {
  return (
    <div className="">
      <div className="t-a-c f-s-3 f-w-600 m-b-3 m-t-3 ">Developer Docs </div>
      <div className="max-w-40 b-r-10 d-b m-a f-s-1_25 box_clr pa-2">
        <div className="m-b-2">
          Explore the technical specs of building on MaalChain. (create the
          section, with “coming soon”)
        </div>
        <div className="d-b m-a t-a-c">
          <img src={coming} alt="" className="max-w-20" />
        </div>
      </div>
    </div>
  );
}

export default Careers;
